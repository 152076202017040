.eboardTitleCard {
    width: 100%;
    height: 90vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.eboardTitleCard h1 {
    position: relative;
    font-size: 8vw;
    text-align: center;

    margin-top: 60vh;
    /* font-family: "Bebas Neue"; */
}

.eboardColorOverlay {
    height: 90vh; /* make sure this is the same height as titlecard */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.eboard {
    width: 100%;
    height: fit-content;

    overflow: hidden;

    padding-bottom: 10vh;

    align-items: center;
    justify-content: center;

    background-color: rgb(107, 144, 128);
}

.eboard h1 {
    font-family: 'Bebas Neue';
    font-weight: 300;
    font-size: 5vw;
    text-align: center;
    color: white;
}

.eboardCardHolder {
    width: 92%;

    margin-left: 4%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3vw;

    justify-content: center;
    align-items: center;
    /* background-color: gray; */
}

.eboardCard {
    width: 100%;
    height: 40vw;

    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    color: white;

    background-color: rgb(255, 255, 255);
    border: 2px solid white;
    border-color: white;
}

.eboardCardPortrait {
    width: 100%;
    height: 100%;

    position: absolute;
    bottom: 0;
    right: 0;

    margin-bottom: 0;
    margin-right: 0;

    background-size: 100%;
    background-position: 50% 50%;

    align-items: center;
    justify-content: center;

    transition: all 0.8s ease;
}

.eboardCard:hover .eboardCardPortrait {
    width: 50%;
    height: 37.5%;

    bottom: 0;
    right: 0;

    margin-bottom: 2.5%;
    margin-right: 5%;

    background-size: 160%;
    background-position: 50% 20%;
    transition: all 0.8s ease;
}

.eboardCardBorder {
    width: calc(100% - 4vw);
    height: calc(100% - 4vw);

    display: grid;
    grid-template-rows: auto auto;
    align-content: end;

    margin-left: 2vw;
    margin-top: 2vw;

    z-index: 2;

    border: 0.2vw solid white;
    border-color: white;
    
    transition: all 0.8s ease;
}

.eboardCard:hover .eboardCardBorder {
    width: calc(100% - 2vw);
    height: calc(100% - 2vw);

    margin-left: 1vw;
    margin-top: 1vw;

    border: 0.15vw solid white;

    transition: all 0.8s ease;
}


.eboardCardText {
    width: 100%;

    opacity: 1;
    transition: all 0.8s ease;
}

.eboardCard:hover .eboardCardText {
    opacity: 0;

    transition: all 0.8s ease;
}

.eboardCardText h1 {
    width: 80%;

    position: relative;
    margin-left: 2%;
    margin-bottom: 0%;

    font-size: 3vw;
    font-weight: 300;
    text-align: left;
    box-shadow: black;
}

.eboardCardText p {
    /* position: absolute;
    bottom: 100%; */
    position: relative;
    margin-left: 2%;
    margin-top: 0%;
    margin-bottom: 2%;
    align-self: end;

    font-size: 1.6vw;
    font-weight: 500;
    text-align: left;
    text-shadow: 30px 30px 60px rgba(0, 0, 0, 0.5);
}

.eboardCardInformation {
    width: 90%;
    height: 90%;

    position: absolute;
    margin: auto;

    /* background-color: grey; */
}

.eboardCardInformation h1 {
    color: black;
    text-align: left;
    font-size: 4.2vw;

    margin-top: 5%;
    margin-bottom: 0;
}

.eboardCardInformation p {
    color: black;
    text-align: left;

    margin-top: 0;
}

.eboardCardInformation h2 {
    color: black;
    text-align: left;
    font-size: 2vw;
    font-family: 'Bebas Neue';
    font-weight: 200;

    margin-bottom: 1%;

}

.eboardCardInformation .icon {
    width: 10%;

    position: absolute;
    bottom: 0;
    margin-bottom: -6%;
}

@media (max-width: 1230px) {

}

@media (max-width: 1024px) {
    .eboardCardHolder {
        width: 92%;
        grid-template-columns: repeat(2, 1fr);
        gap: 3vw;
    }
    .eboardCard {
        height: 60vw;
    }
    .eboardCardText h1 {
        font-size: 5vw;
    }
    .eboardCardText p {
        font-size: 2.6vw;
    }

    .eboardCardInformation h1 {
        font-size: 7vw;
    }

    .eboardCardInformation h2 {
        font-size: 3.5vw;
    }
}

@media (max-width: 768px) { /*tablets*/
    .eboardTitleCard h1 {
        font-size: 14vw;
    }

    .eboard h1 {
        font-size: 8vw;
    }

    .eboardCardHolder {
        grid-template-columns: repeat(1, 1fr);
    }

    .eboardCardBorder {
        width: calc(100% - 8vw);
        height: calc(100% - 8vw);

        margin-top: 4vw;
        margin-left: 4vw;

        border: 0.4vw solid white;
    }

    .eboardCard:hover .eboardCardBorder {
        width: calc(100% - 6vw);
        height: calc(100% - 5vw);

        margin-top: 3vw;
        margin-left: 3vw;
    
        border: 0.4vw solid white;
    
    }
    
    .eboardCard {
        height: 120vw;
    }
    .eboardCardText h1 {
        font-size: 9vw;
    }
    .eboardCardText p {
        font-size: 4.6vw;
    }

    .eboardCardInformation h1 {
        font-size: 16vw;
    }

    .eboardCardInformation h2 {
        font-size: 6vw;
    }
}

@media (max-width: 432px) { /*phones*/

    .eboardCard {
        height: 135vw;
    }

    .eboardCardInformation h1 {
        font-size: 14vw;
    }

    .eboardCardInformation h2 {
        font-size: 6vw;
    }

    .eboardCardInformation p {
        font-size: 3.8vw;
    }
}
