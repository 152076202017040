.flipCard {
    width: 66vw;
    height: 44vw;
    background-color: transparent;
    perspective: 1000px;

    margin-left: 12vw;
    margin-top: 3vw;

    font-family: "ADLaM Display";
}

.flipCard:hover .cardContent {
    transform: rotateY(180deg);
}

.cardContent {
    width: 100%;
    height: 100%;
    
    position: relative;
    text-align: center;

    transition: transform 0.5s;
    transform-style: preserve-3d;
}

.cardFront, .cardBack {
    width: 100%;
    height: 100%;

    position: absolute;
    backface-visibility: hidden;
}

.cardFront {
    background-color: red;
    background-size: cover;
    /* background-clip: content-box; */
}

.cardBack {
    background-color: grey;
    background-size: cover;
    transform: rotateY(180deg);
}

/* .cardBackText {
    width: 90%;
    height: 90%;
    margin: 2vw;

    background-color: grey;
    display: flex;
    flex-direction: row;
    flex: 1;
} */