.galleryTitleCard {
    width: 100%;
    height: 90vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.galleryTitleCard h1 {
    position: relative;
    font-size: 90px;
    text-align: center;
    /* font-family: "Bebas Neue"; */
}

.galleryColorOverlay {
    height: 90vh; /* make sure this is the same height as titlecard */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.gallery h1 {
    font-family: "Bebas Neue";
    font-size: 5vw;
    font-weight: 300;
    color: white;

    margin-top: 2vw;
    margin-bottom: 1vw;
}

.galleryCardContainer {
    width: 98%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1vw;

    margin: 1vw;

    overflow-x: scroll;
    overflow-y: hidden;
}

.galleryCardWrapper {
    width: 100%;
    height: 25vw;

    position: relative;
    overflow: hidden;
}

.galleryCard {
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position: center;
    position: relative;

    transition: transform 0.8s;
}

.galleryCardText {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;

    align-items: flex-start;
    margin: 1.2vw;
}

.galleryCardText h1 {
    font-family: "Bebas Neue";
    font-size: 3.8vw;
    font-weight: 400;
    color: white;
    text-decoration: none;
    text-align: left;

    margin: 0px;
}

.galleryCardText h2 {
    font-family: "Bebas Neue";
    font-size: 2.3vw;
    font-weight: 400;
    color: white;
    text-decoration: none;

    margin: 0px;
}

.galleryCard:hover {
    transform: scale(1.1);
}

.galleryCard::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.gallery {
    width: 100%;
    height: auto;
    background-color: rgb(0, 78, 100);
    text-align: center;

    display: flex;
    flex-direction: column;

    overflow-x: scroll;
}

@media (max-width: 1024px) {
    .galleryTitleCard h1 {    
        font-size: 80px;
        /* font-family: "Bebas Neue"; */
    }
    .galleryCardText h1 {
        font-size: 38px;
    }
    
    .galleryCardText h2 {
        font-size: 23px;
    }
}

@media (max-width: 768px) { /*tablets*/
    .galleryCardContainer {
        flex-direction: column;
        gap:2.5vw;
    
        margin: 2.5vw;

        align-items: center;
    }

    .galleryCardWrapper {
        height: 45vh;
        width: 95vw;
        position: relative;
        overflow: hidden;
    }

    .galleryCardText h1 {
        font-size: 9.2vw;
    }
    
    .galleryCardText h2 {
        font-size: 3.4vw;
    }
    .gallery {
    align-items: center;
    }
} 

@media (max-width: 432px) { /*phones*/
    .galleryTitleCard h1 {
        font-size: 60px;
    }

    .galleryCardContainer {
        margin: 4vw;
        gap: 4vw;
    }

    .galleryCardWrapper {
        height: 38vh;
        width: 92vw;
    }

    .galleryCardText {
        margin: 2.5vw;
    }

    .galleryCardText h1 {
        font-size: 12vw;
    }
    
    .galleryCardText h2 {
        font-size: 5.75vw;
    }
}