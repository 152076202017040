.hackathonNav {
    height: 6.2vw;
    background-color: grey;
    border-radius: 1vw;

    position: fixed;
    bottom: 5vh;
    display: flex;
    z-index: 999;
}

.hackathonNav img{
    height: 5.8vw;
    margin: auto;
}

.hackathonHomeButton{
    height: 6.2vw;
    overflow: hidden;
    position: fixed;
    bottom: 5vh;
    right: 0;
}

.hackathonHomeButton img {
    height: 6.2vw;
    
    object-fit: cover;
    border-top-left-radius: 1vw;
    border-bottom-left-radius: 1vw;

    background-color: gray;
}

.hackathonNavBar {
    width: 50vw;
    border-radius: 1vw;

    display: flex;
    justify-content: space-between;

    padding: 0.1vw;
    padding-left: 3vw;
    padding-right: 3vw;

    background-color: lightgrey;
}

.hackathonNav h1 {
    font-size: 2.3vw;
    font-weight: 300;
    font-family: "ADLaM Display";

    cursor: pointer;

}