.checklistCard {
    width: 100%;
    height: 7vw;

    position: relative;
    display: inline;

    /* overflow: hidden; */
}

.checklistCard button {
    width: 100%;
    height: inherit;
    border-radius: 2vw;

    font-family: "ADLaM Display";
    font-weight: 300;
    font-size: 2.4vw;
    text-align: center;

    cursor: pointer;
    outline: none;
    border: none;
    background-color: rgb(190, 190, 190);
    /* position: relative; */
}

.checklistCard button:hover {
    background-color: grey;
    /* position: relative; */
}

.checklistCardOpen {
    width: 100%;
    height: fit-content;

    border-radius: 2vw;
    position: relative;
    background-color: lightgrey;
}

.checklistCardOpen.active {
    max-height: 999px;
    opacity: 1;
    overflow: hidden;
    transform: translateY(0);
    transition: all 300ms ease;
}

.checklistCardOpen.inactive {
    width: 100%;
    max-height: 0;
    opacity: 0;
    transform: translateY(-2vw);
    transition: all 300ms ease;
}

.checklistCardOpen p {
    font-size: 1.4vw;
    margin: 1.5vw;
}
