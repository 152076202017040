.footer {
    width: 100%;
    height: auto;
    background: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1vh;
}

.socialMedia {
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}


.footer p {
    color: white;
}