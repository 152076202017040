.hackathon {
    height: auto;
    width: 100%;
    background-color: white;
    text-align: center;
    font-family: "ADLaM Display";
}

.hackathon h1 {
    font-size: 3vw;
    font-weight: 800;
}

.hackathonTitleCard {
    height: 90vh;
    width: 95vw;
    /* background-color: rgb(202, 233, 255); */
    margin: 2.5vw;
    border-radius: 2vw;
    z-index: 3;

    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hackathonTitleCard h1 {
    color: white;
    font-size: 5.8vw;
}

.midSection {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    justify-content: center;
}

.midSection h1 {
    transform: translateY(1.5vw);
}

.globe {
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
    background-color: rgb(8, 61, 119);

}

.map { /*https://verpex.com/blog/website-tips/how-to-create-an-infinite-image-slider-using-css*/
    /* height: 100%; */
    /* height: 20vw;
    width: 20vw; */
    border-radius:50%;

    display: grid;
    overflow: hidden;

    transform: translateZ(-10px);

    --d: 50s;
}

.map > img {
    grid-area: 1/1;
    height: 20vw;
    object-fit: cover;
    animation: rotate var(--d) linear infinite;
    overflow: hidden;
}
.map > img:nth-child(1) {animation-delay: calc(0*var(--d)/-2)}
.map > img:nth-child(2) {animation-delay: calc(1*var(--d)/-2)}

.clouds {
    /* height: 20vw;
    width: 20vw; */
    border-radius:50%;

    display: grid;
    overflow: hidden;

    transform: translateZ(-10px);
    transform: translateY(-20vw);
    --d: 30s;
}

.clouds > img {
    grid-area: 1/1;
    height: 20vw;
    object-fit: cover;
    animation: rotate var(--d) linear infinite;
    overflow: hidden;
}

.clouds > img:nth-child(1) {animation-delay: calc(0*var(--d)/-2)}
.clouds > img:nth-child(2) {animation-delay: calc(1*var(--d)/-2)}

@keyframes rotate {
    50%    {transform: translate(-100%)}
    50.01% {transform: translate( 100%)}
}

.hackathonTitleTransition {
    height: 95vh;
    width: 90vw;

    margin: 5vw;
    border-radius: 2vw;

    background-size: cover;
    background-position: 50% 50%;
}

.hackathonTitleTransition h1 {
    font-size: 2.9vw;
    font-weight: 300;
    padding-top: 1.5vw;
}

.hackathonAbout {
    width: 90vw;
    height: 50vw;
    margin: auto;
    overflow: hidden;
    display: flex;

    border-radius: 30px;
    background-size: cover;
}

.hackathonAboutLongBrownThing {
    width: 100%;
    height: 10vw;
    overflow: hidden;

    background-color: green;
}

.hackathonAbout img {
    height: 10vw;
    width: 10vw;
    
    margin-top: 20%;
    position: absolute;
    background-color: transparent;
}

.buttonLeft {
    left: 7.5vw;
}

.buttonRight {
    right: 5vw;
}

.cardSlider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* white-space: nowrap; */
    transition: ease 1s;

    /* background-color: grey; */
}

.card {
    width: 100vw;
    height: 100%;
}

.hackathonSignUp {
    height: 50vw;
    width: 90vw;

    margin: 5vw;
    border-radius: 2vw;

    background-size: cover;
    display: flex;
}

.hackathonSignUp h2 {
    width: 20vw;
    margin-left: 7vw;

    font-weight: 300;
    text-align: center;
    font-size: 2.7vw;
}

.signUpMonitor {
    width: 50.2vw;
    margin-top: 5.6vw;
    margin-left: 8vw;

    overflow: hidden;
    /* background-color: grey; */
}

.signUpMonitor h1 {
    font-size: 5vw;
    font-weight: 400;

    margin-top: 0.5vw;
}

.signUpMonitor p {
    font-size: 2.6vw;
    font-weight: 400;
    margin-top: -2.8vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

.signUpMonitor a {
    /* width: 20vw; */
    background-color: rgb(0, 78, 100);
    border-radius: 2vw;
  
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 3.8vw;
    font-weight: 600;
    
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
}

.hackathonFAQ {
    height: 50vw;
    width: 90vw;

    margin: 5vw;
    border-radius: 2vw;

    background-size: cover;
    background-position: 50% 50%;
}

.FAQText{
    width: 30vw;
    
    position: relative;
    margin-left: 3.4vw;
    top: 1.6vw;
    text-align: left;

}
.hackathonFAQ h1{
    color: rgb(19, 27, 35);
    font-size: 2.75vw;
    font-weight: 300;
}
.FAQContainer {
    width: 31.25vw;
    height: 37vw;

    /* display: flex;
    flex-direction: column; */
    /* gap: 1px; */

    display: inline;
    position: absolute;
    right: 9vw;
    /* margin-left: 54.5vw;
    top: -1.5vw; */

    overflow-y: scroll;

    background-color: white;
}

.hackathonSponsors {
    height: fit-content;
    width: 95vw;

    margin: 2.5vw;
    border-radius: 3vw;
    padding-bottom: 10vw;

    background-size: cover;
    background-repeat: no-repeat;

}

.hackathonSponsors h2 {
    /* width: 20vw; */
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 8vw;
    font-weight: 300;

    position: relative;
    margin-left: -30vw;
    top: 3vw;

    margin-bottom: 60vw;
}

.hackathonSponsors h1 {
    /* width: 20vw; */
    border-radius: 2vw;
  
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 5vw;
    font-weight: 300;
}

.hackathonSponsors p {
    /* width: 20vw; */
    border-radius: 2vw;
  
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 3.8vw;
    font-weight: 300;
}

.hackathonSponsors a {
    /* width: 20vw; */
    background-color: rgb(0, 78, 100);
    border-radius: 2vw;
  
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 3.8vw;
    font-weight: 600;
    
    padding: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
}

.hackathonSponsors img {
    /* width: 33vw; */
    height: 18vw;
    margin-bottom: 3vw;
}

.hackathonSponsorsText {
    width: fit-content;
    height: fit-content;

    padding: 3vw;
    padding-top: 0vw;
    padding-bottom: 4vw;
    border-radius: 2vw;

    margin-left: 11.5vw;
    margin-top: 5.5vw;

    background-color: rgb(22, 105, 122);
}

@media (max-width: 432px) {

    .hackathonTitleCard h1 {
        font-size: 12.5vw;
    }

    .midSection {
        align-items: center;
        justify-content: center;
    }

    .midSection h1{   
        font-size: 16vw;
    }
    
    .globe {
        height: 30vw;
        width: 30vw;
    }
    .map > img {
        height: 30vw;
    }
    .clouds {
    transform: translateY(-30vw);
    }
    .clouds > img {
        height: 30vw;
        transform: translateZ(-10vw);
    }
    
    .hackathonTitleTransition h1 {
        font-size: 5vw;

        padding-top: 5vw;
        margin-left: 5vw;
        margin-right: 5vw;
    }
}