.navbar {
    width: 100vw;
    height: 14vh;

    position:fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;

    z-index: 10;
}

.navbar a {
    font-weight: 300;
    color: white;
    text-decoration: none;
}

.navbarLogo {
    height: 100%;

    flex: 50%;
    display: flex;
    align-items: center;
    margin-left: 3vw;
}

.navbarLogo img {
    height: 6.5vh;
}

.navbarElements {
    height: 100%;

    display: flex;
    gap: 2.5vw;

    align-items: center;
    justify-content: center;

    margin-right: 2vw;
}

.navbarElements h1 {
    font-weight: 300;
}


.navbarElements img {
    width: 6vh;
    height: 6vh;

    cursor: pointer;
    object-fit: scale-down;
    margin-right: 1vw;
}

.navMenuContainer {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    pointer-events: none;

    z-index: 9;
}

.navMenu {
    width: 100vw;
    height: 100vh;

    background: linear-gradient(180deg, rgba(0,78,100,1) 0%, rgba(107,144,128,1) 100%);
   
    display: flex;

    padding-top: 14vh;
    padding-left: 3vw;

    pointer-events: auto;

    z-index: 10;
}

.navMenuCardContainer {
    height: 100%;
    
    display: flex;
    flex-direction: row;
    gap: 1vw;

    color: white;
    font-family: "Bebas Neue";

}

/* #region big card */

.navMenuBigCardWrapper {
    height: 81vh;
    width: 30vw;
    border-radius: 1.5vw;

    position: relative;
    overflow: hidden;
}

.navMenuBigCardWrapper h1 {
    font-size: 5.8vw;
    font-weight: 300;

    position: absolute;
    bottom: 0;
    margin-left: 5%;

    color: white;
}

@media screen and (max-width: 1140px) {

}

.navMenuBigCard::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.navMenuBigCard {
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
    transition: transform 0.6s;
}

.navMenuBigCard:hover {
    transform: scale(1.1);
}


/* #endregion */

/* #region small card */

.navMenuSmallCardContainer {
    display: flex;
    flex-direction: column;
    gap: 1vh
}

.navMenuSmallCardWrapper {
    height: 40%;
    width: 30vw;
    border-radius: 20px;

    position: relative;
    overflow: hidden;
}

.navMenuSmallCardWrapper Link{
    color: white;
    text-decoration: none;
}

.navMenuSmallCard::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.navMenuSmallCard {
    width: 100%;
    height: 100%;
    border-radius: 20px;

    background-size: cover;
    background-position: center;
    cursor: pointer;
    transition: transform 0.6s;
}

.navMenuSmallCard:hover{
    transform: scale(1.1);
}

.navMenuSmallCardWrapper h1 {
    font-size: 5vw;
    font-weight: 300;
    color: white;
    text-decoration: none;

    position: absolute;
    top: 0;
    right: 0;

    margin-right: 5%;
}

/* #endregion */

.navMenuTextContainer {
    width: 25vw;

    display: flex;
    flex-direction: column;

    margin-left: 2vw;
    margin-top: 36vh;

    color: white;
    font-family: "Bebas Neue";
}

.navMenuTextContainer p {
    font-size: 2vw;
    margin-bottom: 1vh;
}

.navMenuPageContainer {
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.navMenuPageContainer a {
    color: white;
    text-decoration: none;
    flex: 1;
    font-size: 4vw;
}

.navMenuLinkContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3vw;

    font-size: 3vw;
}

.navMenuLinkContainer a {
    color: white;
    text-decoration: none;
}

.navMenuIconContainer {
    position: absolute;
    bottom: 0;
    margin-bottom: 4.6vh;

    display: flex;
    gap: 1vw;
}

@media screen and (max-width: 1140px) {
    .navbarElements img {
        height: 80px;
    }

    .navMenuBigCardWrapper h1 {
        font-size: 65px;
    }
    .navMenuSmallCardWrapper h1 {
        font-size: 65px;
    }

    .navMenuPageContainer a {
        font-size: 4.5vw;
    }

    .navMenuPageContainer {
        gap: 2vw;
    }

    .navMenuPageContainer a{
        font-size: 19w;
    }
    
    .navMenuTextContainer p {
        font-size: 3vw;
        margin-top: 9vh;
    }

    .navMenuLinkContainer a{
        font-size: 4vw;
    }
}

@media (max-width: 768px) { /*tablets*/
    .navMenu {
        flex-direction: column;
    }

    .navbarElements h1 {
        display: none;
    }

    .navMenuCardContainer {
        flex-direction: column;
        gap: 2vh;
    }
    /* #region big card */

    .navMenuBigCardWrapper {
        height: 22.5vh;
        width: 94.5%;
    }

    .navMenuBigCard {
        background-size: cover;
        background-position: 50% 30%;
    }

    .navMenuBigCardWrapper h1 {
        font-weight: 300;

        position: absolute;
        left: 0;
        margin-left: 3%;
        margin-bottom: 0;
    }

    /* #endregion*/

    /* #region small card */

    .navMenuSmallCardContainer {
        flex-direction: row;
        gap: 3vw;
    }

    .navMenuSmallCardWrapper {
        height: 24vh;
        width: 45.675vw;
    }

    .navMenuSmallCard {
        width: 100%;
        height: 100%;
        border-radius: 3vw;
    }

    .navMenuSmallCardWrapper h1 {
        font-size: 50px;
        font-weight: 350;

        margin-top: 15vh;
    }
    /* #endregion */

    .navMenuTextContainer {
        width: 25vw;

        margin-top: -48vh;
        margin-left: 1vw;

        color: white;

        /* display: flex;
        flex-direction: row; */
    }

    .navMenuPageContainer a {
        color: white;
        text-decoration: none;
        flex: 1;
        font-size: 50px;
    }

    .navMenuTextContainer p {
        margin-top: 4.5vh;

        font-size: 25px;
    }

    .navMenuLinkContainer {
        width: 80vw;

    }

    .navMenuLinkContainer a {
        font-size: 30px;
    }

    .navMenuIconContainer {
        position: absolute;
        right: 0;
        margin-right: 5vw;
    }
}

@media (max-width: 432px) { /*phones*/

    /* #region big card */
    .navMenuBigCardWrapper h1 {
        font-size: 50px;
    }
    /* #endregion */

    /* #region small card */
    .navMenuSmallCardWrapper {
        border-radius: 1vw;
    }

    .navMenuSmallCardWrapper h1 {
        font-size: 40px;
    }
    /* #endregion */

    .navMenuPageContainer a {
        font-size: 40px;
    }

    .navMenuIconContainer {
        width: 25vw;
        gap: 3vw
    }
    .navMenuIconContainer .icon{
        width: 100%;
    }
}

