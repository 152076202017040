/* #region title card */

.eventTitleCard {
    width: 100%;
    height: 90vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.eventTitleCard h1 {
    position: relative;
    font-size: 90px;
    /* font-family: "Bebas Neue" */
}

.eventColorOverlay {
    height: 90vh; /* make sure this is the same height as titlecard */

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}
/* #endregion */

/* #region eventPostList */
.eventPostList {
    background-color: rgb(107, 144, 128);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    overflow: hidden;
}

.eventPostList h1 {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    font-family: 'Bebas Neue';
    font-size: 4vw;
    font-weight: 300;
    color: white;
}

.eventPostList h3 {
    color: white;
    margin-top: 0px;
    font-weight: 400;
}
/* #endregion */

.postContainer {
    width:9 0vw;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 1rem;
    margin-bottom: 8vh;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    /* background-color: grey; */
}

/* #region eventPost */
.eventPost {
    position: relative;
    width: 30vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(247, 255, 247);
    border-radius: 10px;

}

.eventPost img {
    margin: 2.3vh;
    margin-top: 1vh; 
    object-fit: scale-down;
    /* border-radius: 10px; */
}

.eventPostText {
    margin-top: -3.8vh;
    display: flex;
    flex-direction: row;
}

.eventPostDateTimeLocation {
    margin: 1vw;
    margin-top: 0px;
}

.eventPostDateTimeLocation h2 { /*controls how big the day number is*/
    font-size: 45px;
    font-weight: 900;
    margin-top: -1vh;
}

.eventPostDateTimeLocation p {
    font-size: 18px;
    text-overflow: clip;
}

.eventPostInfo {
    /* margin: 1rem; */
    white-space: normal;
    overflow: overlay;
    margin-right: 1vw;
}

.eventPostInfo h1 {
    font-size: 2vw;
    font-weight: 400;
    color: black
}

.eventPostInfo p {
    font-size: 1.25vw;
}

/* #endregion */

@media (max-width: 1024px) {
    .eventTitleCard h1 {
        font-size: 80px;
    }
    .postContainer {
        flex-direction: column;
    }
    .eventPost {
        width: 85vw;
        height: 40vh;
        flex-direction: row;
    }

    .eventPost img {
        height: auto;
        margin: 1rem;
    }

    .eventPostText {
        margin: 0.3vw;
        margin-left: -10px;
    }

    .eventPostInfo h1 {
        font-size: 3vw;
    }
    .eventPostInfo p {
        font-size: 2vw;
    }
}

@media (max-width: 768px) { /*tablets*/
    .eventPost {
        height: 40vw;
    }

    .eventPostDateTimeLocation p {
        font-size: 3vw;
    }

    .eventPostDateTimeLocation h2 {
        font-size: 6vw;
    }

} 

@media (max-width: 432px) { /*phones*/
    .eventTitleCard h1 {
        font-size: 60px;
    }
    .postContainer {
        flex-direction: column;
    }
    .eventPost {
        width: 85vw;
        height: 45vw
    }

    .eventPost img {
        width: 40vw;
        margin: 1vh;
        margin-top: 1vh; 
        object-fit: scale-down;
        /* border-radius: 10px; */
    }

    .eventPostDateTimeLocation p {
        font-size: 3.8vw;
    }

    .eventPostDateTimeLocation h2 {
        font-size: 8vw;
    }

    .eventPostInfo {
        display: none;
        width: 0px;
        height: 0px;
    }
    
}