.aboutTitleCard {
    width: 100%;
    height: 90vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.aboutTitleCard h1 {
    position: relative;
    font-size: 8vw;
    font-weight: 600;
    text-align: center;
    /* font-family: "Bebas Neue"; */
}

.aboutColorOverlay {
    height: 90vh; /* make sure this is the same height as titlecard */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.aboutMission {
    width: 100%;
    height: auto;

    overflow: auto;
    display: flex;
    flex-direction: column;

    background-color: rgb(0, 78, 100);
    color: white;
    text-align: center;
    align-items: center;
}

.aboutMission img {
    width: 80vw;
    height: 85vh;
    margin: 2vw;
    object-fit: cover;

    border-radius: 16px;
}

.aboutMission h1 {
    font-family: 'Bebas Neue';
    font-size: 5vw;
    font-weight: 300;
    
    margin-bottom: 0;
}

.aboutMission p {
    font-size: 1.75vw;
    margin-left: 8vw;
    margin-right: 8vw;
}

.aboutHistory {
    height: auto;
    overflow: auto;
    background-color: rgb(247, 255, 247);
    color:black;
    font-size: 1.5vw;  
    display: flex; 
    flex-direction: column;
}

.aboutHistory h1 {
    margin: auto;
    margin-top: 3vh;
    margin-bottom: 3vh;

    font-family: 'Bebas Neue';
    font-weight: 300;
    font-size: 5vw
}

.historyCardSlider {
        overflow: hidden;
  position: relative;
}

.historyCardContainer {
    height: 95vh;
    width: auto;

    display: flex;
    flex-direction: row;
    /* gap: 10vw; */

    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

}

.historyCard {
    position: relative;
    width: 80vw;

    margin-left: 20vw;
    display: flex;
    flex-direction: column;
    color: black;
    white-space: normal;
    text-align: center;

}

.historyCard img {
    height: 70vh;
    width: 60vw;
    object-fit: cover;
}

.historyCardBlurb {
    height: auto;
    margin: 1rem;
    overflow: hidden;
    word-wrap: break-word;
    vertical-align: middle;

}


@media (max-width: 1024px) {
    .aboutTitleCard h1 {    
        font-size: 80px;
        /* font-family: "Bebas Neue"; */
    }
    .aboutMission h1 {
        font-size: 50px;
        margin: 4vw;
        margin-bottom: -1vw;
    }
    .aboutMission p {
        font-size: 25px;
    }
    
    .aboutMission img {
        height: 58vh;
    }

    .historyCard {
        margin-left: 10vw;
    }
    .historyCard img {
        width: 80vw;
    }
}

@media (max-width: 768px) { /*tablets*/

} 

@media (max-width: 432px) { /*phones*/
    .aboutTitleCard h1 {    
        font-size: 16vw;
        /* font-family: "Bebas Neue"; */
    }

    .aboutMission h1 {
        font-size: 8vw;
    }

    .aboutMission p {
        font-size: 4vw;
        margin: 1rem;
    }
    
    .aboutMission img {
        height: 35vh;
        margin-bottom: 1vh;
    }

    .aboutHistory h1 {
        font-size: 8vw;
    }

    .historyCardContainer {
        height: 93vh;
    }

    .historyCard img {
        height: 50vh;
        width: 80vw;
    }

    .historyCardBlurb {
        font-size: 4vw;
        margin-top: 0.5vh;
    }
}