.home {
  overflow: hidden;
  position: relative;
}
.pillarContainer {
  white-space: nowrap;
  position: relative;
  transition: ease 1000ms;
}

.pillar {
  width: 100%;
  height: 95vh;
  position: relative;

  display: inline-block;

  align-items: center;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.colorOverlay {
  width: 100%;
  height: 100%;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: inherit;
  pointer-events: none;

  z-index: 0;
}

.headerContainer {
  width: 300px;
  margin-top: auto;
  margin-bottom: 5vh;
  margin-left: 3vw;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  position: absolute;
  bottom: 0;
}

.pillarTitle {
    font-size: 120px;
    font-weight: 500;
    font-family: "Bebas Neue";
    color: white;
    line-height: 0.9;
}

.pillarDesc {
  width: 20vw;
  color: white;
}

.missionStatement {
  margin-top: -1vh; /*for some reason theres a white line between this and the title card*/
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  background-color: rgb(107, 144, 128);
  color: white;

  /* font-family: "Open Sans"; */
}

.missionStatement a {
  height: auto;
  width: 15vw;
  background-color: rgb(0, 78, 100);
  border-radius: 5px;

  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 1.7vw;
  font-weight: 600;

  padding: 2.2vh;
}

.missionStatement h1 {
  /* margin-top: 100px;
  height: 200px; */
  margin: 1vw 5vw -1vw 5vw;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 2vw;

}

.missionStatement h2 {
  /* margin-top: 100px;
  height: 200px; */
  margin: 2.3vw;
  margin-bottom: -1vh;

  font-family: "Bebas Neue";
  font-weight: 300;
  font-size: 4vw;

  justify-content: center;
  text-align: center;
  /* font-weight: 100; */
}

.missionStatement p {
  font-weight: 200;
  margin: 3rem;
}

.missionPillarContainer {
  width: 95vw;

  margin-bottom: 10vh;
  margin-top: 3vh;

  display: flex;
  flex-direction: row;
  gap: 2vw;
}

.missionPillar {
  width: 30vw;
  height: 30vw;

  flex: 1;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;
  border-radius: 2vw;

  transition: all 0.3s ease;
}

.missionPillar:hover {
  width: 50vw;
  height: 30vw;
  flex: 2;

  position: relative;

  transition: all 0.3s ease;
}

.missionPillar h1 {
  width: 50%;

  font-size: 3.5vw;
  font-weight: 200;
  font-family: "Bebas Neue";
  line-height: 0.9;
  text-align: left;

  margin: auto;
  margin-left: 5%;
  margin-bottom: 5%;
  bottom: 0;
  position: absolute;

  justify-content: center;
  align-items: center;

  transition: all 0.2s ease;
}

.missionPillar:hover h1 {
  font-size: 5vw;

  transition: all 0.2s ease;
}

.missionPillar p {
  width: 40%;
  font-size: 0vw;

  position: absolute;
  right: 0;
  top: 0;

  text-align: right;
  font-family: 'Montserrat';
  transition: all 0.2s ease;
}

.missionPillar:hover p {
  width: 40%;

  font-size: 1.25vw;
  font-weight: 400;

  transition: all 0.2s ease;
}

.calendar {
  background-color: rgb(0, 78, 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.calendar h1 {
  font-family: "Bebas Neue";
  font-weight: 300;
  font-size: 4vw;
  margin-bottom: 0;
}

.calendar img {
  width: 80vw;
  object-fit: contain;
  margin: 1rem;
  margin-bottom: 10vh;
}

.homeSponsors {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(107, 144, 128);
}

.homeSponsors h1 {
  font-family: "Bebas Neue";
  font-weight: 300;
  font-size: 4vw;
  color: white;
  text-align: center;

  margin-top: 6vh;
}

.homeSponsors p {
  color: white;
  text-align: center;
  margin-bottom: 3vh;
  font-size: 20px;
}

.homeSponsorsList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.homeSponsors img {
  height: 10vh;
  object-fit: contain;
  margin-left: 1vw;
}

.homeSponsors a {
  height: fit-content;
  width: 30vw;
  background-color: rgb(0, 78, 100);
  border-radius: 5px;

  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
  font-family: 'Bebas Neue';

  padding: 2vh;
  margin-bottom: 6vh;
}

@media (max-width: 1366x) { /*desktop*/
/*dont need anything in here but just leaving it here for standard formatting*/
}

@media (max-width: 1024px) {
  .missionPillarContainer {
    margin-top: 2vh;
    margin-bottom: 6vh;
  }

  .missionPillar:hover h1 {
    font-size: 4vw;
  
    transition: all 0.2s ease;
  }

  .missionPillar p {
    font-size: 0vw;

    position: absolute;
    right: 0;
    top: 0;
    margin-top: 5%;
    margin-right: 5%;  
  }
  .missionPillar:hover p {
    font-size: 1.65vw;
  }
  /* .calendar img {
    height: 65vh;
    object-fit: contain;
    margin: 1rem;
    margin-bottom: 80px;
  } */
}

@media (max-width: 768px) { /*tablets*/

  .missionStatement h1 {
    margin-top: 3vw;
    font-size: 4.7vw;
  }

  .pillarTitle {
    font-size: 90px;
    font-weight: 500;
    font-family: "Bebas Neue";
    color: white;
    line-height: 0.9;
  }

  .missionPillarContainer {
    width: 85vw;
    height: 175vw;
    display: flex;
    flex-direction: column;
    gap: 5vw;

    margin-bottom: 10vh;
  }

  .missionPillar {
    width: 100%;
  
    flex: 1;
  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    position: relative;
    border-radius: 2vw;
  
    transition: all 0.3s ease;
  }

  .missionPillar:hover {
    width: 100%;
    flex: 2;
  
    position: relative;
  
    transition: all 0.3s ease;
  }

  .missionPillar h1 {
    width: 50%;
    font-size: 8vw;
  }

  .missionPillar:hover h1 {
    font-size: 10vw;

  }

  .missionPillar:hover p {
    width: 60%;
    font-size: 4vw;
  }

  .calendar img {
    width: 90vw;
    object-fit: contain;
    margin: 1rem;
    margin-bottom: px;
  }

  .homeSponsors {
    height: fit-content;
  }

  .homeSponsors img{
    height: 10vw;
  }

  .homeSponsors p {
    width: 90%;
    font-size: 3vw;
  }

  .homeSponsors a {
    width: 35%;
    font-size: 3.5vw;

    padding: 3vw;
  }

}

@media (max-width: 432px) { /*phones*/
  .pillarTitle {
    font-size: 70px;
    font-weight: 300;
    font-family: "Bebas Neue";
    color: white;
    line-height: 0.9;
  }

  .missionStatement h1 {
    font-size: 5.2vw;
  }

  .missionStatement h2 {
    font-size: 8vw;
    
    margin-top: 4vh;
  }

  .missionPillarContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10vh;
    gap: 5vw;
  }

  .missionPillar {
    width: 85vw;
    height: fit-content;
    background-color: rgb(0, 78, 100);
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
  }

  .missionPillar h1 {
    font-size: 8vw;
    margin-bottom: 5%;
  }

  .missionPillar:hover p {
    font-size: 5vw;
    margin-left: 5%;
    margin-bottom: 5vw;
  }

  .calendar h1 {
    font-size: 8vw;
    margin-bottom: 0px;
  }

  .homeSponsors {
    height: fit-content;
  }

  .homeSponsors h1 {
    font-size: 8vw;
    margin-top: 4vh;
    color: white;
  }

  .homeSponsors p {
    font-size: 4vw;
    color: white;
    text-align: center;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-bottom: 3vh;
  }

  .homeSponsorsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
  }

  .homeSponsors a {
    width: 50vw;
    font-size: 6.5vw;
  }
}